import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>2023 Personal Annual Report  💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>2023 Personal Annual Report</h1>
        
          <StaticImage
          layout="constrained"
          src="images/7-simple-questions.png"
          alt="Halifaxs cheapest internet provider talks about how to break free from ISP-Provided email address"
          /> 

        <h2> 7 simple questions to help you reflect on the past year and make the next year unstoppable.</h2>
        <br></br>
        <br></br>
        <h1>1. You’re Fired</h1>
        <h3>The most successful people ruthlessly analyze what’s working and what’s not.</h3>
        <p><em>Imagine you took over your life today. If you wanted to be more successful, what would you stop? What would you start? What’s getting in the way? </em></p>
        <h3><strong>If I took over my life from scratch today, what would I immediately stop doing? </strong> </h3>
        <br></br>
        <br></br>
        <h3><strong> What would I start doing?</strong> </h3>
        <br></br>
        <br></br>
        <br></br>
        <h1>2. More and Less</h1>
        <h3>You’ll never reach the top of the mountain unless you enjoy the climb.</h3>
        <p><em>Hint: What gave you energy? What drained you? What gave you meaning? What seemed void?</em></p>
        <h3><strong>What do I want to spend MORE time on next year? </strong> </h3>
        <br></br>
        <br></br>
        <h3><strong> What do I want to spend LESS time on next year?</strong> </h3>
        <br></br>
        <br></br>
        <br></br>
        <h1>3. Passive Mode</h1>
        <h3>The most successful people don’t wait around for things to happen to them, they make things happen by going positive and go first</h3>
        <p><em>Hint: Stop waiting for someone to make the first move. It doesn’t matter if it is an apology or asking someone out on a date. Stop waiting for life to give you what you deserve and go out and make it happen.</em></p>
        <h3><strong>Where am I waiting for another person to make the first move?</strong> </h3>
        <br></br>
        <br></br>
        <h3><strong>What can I do to go positive and go first?</strong> </h3>
        <br></br>
        <br></br>
        <br></br>
        <h1>4. Planting Seeds</h1>
        <h3>The most successful people are always planting seeds for the future.</h3>
        <p><em>Hint: Sometimes it’s about getting organized and sometimes it’s about spending less money to get out of debt. The key is to do the things you need to do today to put yourself in a better position tomorrow. </em></p>
        <h3><strong>What can I do in the next week that will make the rest of the year easier?</strong> </h3>
        <br></br>
        <br></br>
        <h3><strong>What can I do this year that will leave me in a better position for next year?</strong> </h3>
        <br></br>
        <br></br>
        <br></br>
        <h1>5. The Inner Circle</h1>
        <h3>The most successful people are conscious about who they spend time with. </h3>
        <p><em>Willpower can only do so much. You eventually adopt the habits and mindset of the people you spend your time with. Select your friends as you would a personal board of directors. </em></p>
        <h3><strong>Who do I spend time with that pulls me down?</strong> </h3>
        <br></br>
        <br></br>
        <h3><strong>Who do I spend time with that lifts me up?</strong> </h3>
        <br></br>
        <br></br>
        <br></br>
        <h1>6. Film Crew</h1>
        <h3>If there was a film crew following you around all day documenting your success, you’d do all the things successful people do. </h3>
        <p><em>Hint: You wouldn’t be endlessly scrolling social media if someone was watching.  </em></p>
        <h3><strong>What am I doing that I’d want the film crew to see?</strong> </h3>
        <br></br>
        <br></br>
        <h3><strong>What are the things I’m doing that I don’t want the film crew to see?</strong> </h3>
        <br></br>
        <br></br>
        <br></br>
        <h1>7. Goals</h1>
        <h3>The most successful people have goals they want to accomplish AND the systems in place to make them inevitable.</h3>
        <p><em>Hint: Yearly goals are great to keep your eye on the prize but it’s better to break them into weekly or monthly goals to keep you going. Write them down and put them where you can see them.  </em></p>
        <h3><strong>What are my goals for 2024?</strong> </h3>
        <br></br>
        <br></br>
        <h3><strong>Do I have the right systems in place to make them happen? What needs to change?</strong> </h3>
        <br></br>
        <br></br>
        <br></br>
         <p>
            With much love <br></br>
            Bradley Farquhar<br></br>
            CEO - Purple Cow Internet<br></br>
            <em>Orginal article can be found on THE BRAIN FOOD NEWSLETTER</em>
            <br></br><br></br>
        </p>

      </section>
    </LayoutNew>
  )
}
